<template>
    <div class="px-10 w-5/6">
        <div class="flex gap-5 py-10">
            <div class="w-1/2 gap-5 flex">
                <input
                    type="text"
                    class="w-full px-5 py-2 rounded border"
                    placeholder="Enter keyword"
                    v-model="keyword"
                >
                <button
                    type="button"
                    class="px-5 py-3 bg-peach text-white rounded"
                    @click="fetchCodes"
                >
                    Search
                </button>
            </div>

            <div class="flex gap-5 justify-end w-1/2">
                <input
                    type="number"
                    class="px-5 py-2 rounded border"
                    placeholder="Number of Codes"
                    v-model="maxCodes"
                >

                <button
                    type="button"
                    class="bg-peach px-5 rounded text-sm text-white"
                    :class="{ 'cursor-not-allowed opacity-75' : !maxCodes }"
                    :disabled="!maxCodes"
                    @click="generateCodes"
                >
                    Generate Codes
                </button>
            </div>
        </div>

        <div
            v-if="AUTH_USER.data.is_tourism === 0"
            class="bg-peach flex font-medium items-center p-5 rounded-t-lg text-white"
        >
            <div class="w-1/5">Code</div>
            <div class="w-1/5">Status</div>
            <div class="w-1/5">Name</div>
            <div class="w-1/5">generiert am</div>
            <div class="w-1/5 text-center">Action</div>
        </div>
        <div
            v-else
            class="bg-peach flex font-medium items-center p-5 rounded-t-lg text-white"
        >
            <div class="w-1/6">Code</div>
            <div class="w-1/6">Status</div>
            <div class="w-1/6">Name</div>
            <div class="w-1/6">Company</div>
            <div class="w-1/6">generiert am</div>
            <div class="w-1/6 text-center">Action</div>
        </div>

        <template v-if="codes.total !== 0">
            <template v-if="AUTH_USER.data.is_tourism === 0">
                <div
                    v-for="(code, index) in codes.data"
                    class="flex gap-5 items-center p-5 text-sm"
                    :class="{ 'bg-white' : !(index % 2), 'bg-gray-100' : index % 2, 'rounded-b-lg' : index == codes.data.length - 1 }"
                    :key="code.id"
                >
                    <div class="font-bold text-peach w-1/5 tracking-widest">{{ code.value }}</div>
                    <div class="w-1/5">
                        <span
                            class="text-white text-xs px-3 py-1 rounded-full"
                            :class="{ 'bg-red-600' : !code.is_active, 'bg-green-600' : code.is_active }"
                        >
                            {{ code.is_active ? 'Active' : 'Inactive' }}
                        </span>
                    </div>
                    <div class="w-1/5">{{ code.user_details ? `${code.user_details.firstName} ${code.user_details.lastName}` : ''}}</div>
                    <div class="w-1/5">{{ code.created_at }}</div>
                    <div class="w-1/5 flex flex-col gap-3">
                        <button
                            v-if="code.user"
                            class="py-2 rounded text-sm text-white w-full"
                            :class="{ 'bg-red-600' : code.is_active, 'bg-green-600' : !code.is_active }"
                            @click="toggleStatus(code.id, code.is_active)"
                        >
                            {{ code.is_active ? 'Deactivate' : 'Activate' }}
                        </button>

                        <button
                            class="py-2 rounded text-sm text-white w-full"
                            :class="{ 'bg-red-600' : code.is_active, 'bg-green-600' : !code.is_active }"
                            @click="onDownloadPDF(code.value)"
                        >
                            Download PDF
                        </button>
                    </div>
                </div>
            </template>

            <template v-else>
                <div
                    v-for="(code, index) in codes.data"
                    class="flex gap-5 items-center p-5 text-sm"
                    :class="{ 'bg-white' : !(index % 2), 'bg-gray-100' : index % 2, 'rounded-b-lg' : index == codes.data.length - 1 }"
                    :key="code.id"
                >
                    <div class="font-bold text-peach w-1/6 tracking-widest">{{ code.value }}</div>
                    <div class="w-1/6">
                        <span
                            class="text-white text-xs px-3 py-1 rounded-full"
                            :class="{ 'bg-red-600' : !code.is_active, 'bg-green-600' : code.is_active }"
                        >
                            {{ code.is_active ? 'Active' : 'Inactive' }}
                        </span>
                    </div>
                    <div class="w-1/6">{{ code.user_details ? `${code.user_details.firstName} ${code.user_details.lastName}` : ''}}</div>
                    <div class="w-1/6">{{ code.company ? code.company.name : ''}}</div>
                    <div class="w-1/6">{{ code.created_at }}</div>
                    <div class="w-1/6 flex flex-col gap-3">
                        <button
                            v-if="code.user"
                            class="py-2 rounded text-sm text-white w-full"
                            :class="{ 'bg-red-600' : code.is_active, 'bg-green-600' : !code.is_active }"
                            @click="toggleStatus(code.id, code.is_active)"
                        >
                            {{ code.is_active ? 'Deactivate' : 'Activate' }}
                        </button>

                        <button
                            class="py-2 rounded text-sm text-white w-full"
                            :class="{ 'bg-red-600' : code.is_active, 'bg-green-600' : !code.is_active }"
                            @click="onDownloadPDF(code.value)"
                        >
                            Download PDF
                        </button>
                    </div>
                </div>
            </template>
        </template>

        <template v-else>
            <div class="text-center text-2xl mt-3">
                No data found.
            </div>
        </template>

        <template v-if="codes.total !== 0">
            <TailwindPagination
                :data="codes"
                @pagination-change-page="fetchCodes"
            />
        </template>
    </div>
</template>

<script>
    import TailwindPagination from 'laravel-vue-pagination'

    export default {
        components: {
            TailwindPagination
        },
        data() {
            return {
                codes: [],
                limit: 10,
                maxCodes: 0,
                page: 1,
                keyword: ''
            }
        },
        computed: {
            AUTH_USER() {
                return this.$store.getters.AUTH_USER;
            },
        },
        mounted() {
            this.fetchCodes()
        },
        methods: {
            async fetchCodes(page = undefined) {
                try {
                    await this.$store.commit('SET_CODES', [])
                    await this.$store.commit('SET_IS_LOADING', { status: 'open' })

                    const { codes } = await this.$store.dispatch('FETCH_CODES', {
                        pagination: this.limit,
                        page: page ?? this.page,
                        keyword: this.keyword
                    })

                    this.codes = codes

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                } catch(error) {
                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                }
            },
            async generateCodes() {
                try {
                    await this.$store.commit('SET_IS_LOADING', { status: 'open' })

                    const data = await this.$store.dispatch(
                        'GENERATE_CODES',
                        {
                            max: this.maxCodes
                        }
                    )

                    this.maxCodes = 0
                    this.page = 1
                    this.keyword = ''
                    this.fetchCodes()

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                } catch(error) {
                    console.log('error', error)

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                }
            },
            async toggleStatus(id, status) {
                try {

                    const data = await this.$store.dispatch(
                        'TOGGLE_CODE_STATUS',
                        {
                            id: id,
                            status: status ? 'inactive' : 'active'
                        }
                    )

                    this.maxCodes = 0
                    this.page = 1
                    this.keyword = ''
                    this.fetchCodes()

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                } catch(error) {
                    console.log('error', error)

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                }
            },
            async onDownloadPDF(code) {
                try {
                    await this.$store.commit('SET_IS_LOADING', { status: 'open' })

                    const data = await this.$store.dispatch(
                        'DOWNLOAD_CODE_PDF',
                        {
                            code: code
                        }
                    )

                    // const url = window.URL.createObjectURL(new Blob([data]))
                    // const link = document.createElement('a')

                    // link.href = url
                    // link.setAttribute('download', `${code}.pdf`)
                    // document.body.appendChild(link)
                    // link.click()

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                } catch(error) {
                    console.log('error', error)

                    await this.$store.commit('SET_IS_LOADING', { status: 'close' })
                }
            }
        }
    }
</script>

<style>
    ul.pagination {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 20px; 
    }
</style>