<template>
    <MainLayout title="Employee Codes">
        <template #content>
            <CodeList />
        </template>
    </MainLayout>
</template>

<script>
import MainLayout from '_layouts'
import CodeList from '_components/Tourism/CodeList'

export default {
    components: {
        MainLayout,
        CodeList,
    }
}
</script>